<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex">
                    <h5><font-awesome-icon icon="project-diagram" /> Projeto</h5>
                    <btn-refresh @click="atualizar()" v-if="acao === 'ATUALIZAR'"></btn-refresh>
                </div>
                <erros-box :erros="erros"></erros-box>
                <div class="p-formgrid p-grid p-fluid">
                    <div class="p-field p-col-12 p-md-3">
                        <label class="required">CNPJ</label>
                        <InputMask v-model="cnpj" mask="99.999.999/9999-99" :unmask="true" :disabled="desabilitaForm" @keypress="v$.cnpj.$touch()" />
                        <small class="p-error" v-if="v$.cnpj.$error">O CNPJ é obrigatório</small>
                    </div>
                    <div class="p-field p-col-12 p-md-9">
                        <label class="required">Nome</label>
                        <InputText type="text" v-model="nome" :disabled="desabilitaForm" @input="v$.nome.$touch()" />
                        <small class="p-error" v-if="v$.nome.$error">O nome do projeto é obrigatório</small>
                    </div>
                    <div class="p-field p-col-12 p-md-12">
                        <label class="required">Descrição</label>
                        <InputText type="text" v-model="descricao" :disabled="desabilitaForm" @input="v$.descricao.$touch()" />
                        <small class="p-error" v-if="v$.descricao.$error">A descrição do projeto é obrigatória</small>
                    </div>
                    <div class="p-field p-col-12 p-md-4 p-lg-4 p-xl-3">
                        <label class="required">Tipo</label>
                        <Dropdown v-model="tipo" :options="tiposProjetos" optionLabel="descricao" optionValue="id" />
                    </div>
                    <div class="p-field p-col-12">
                        <label>Observações</label>
                        <Textarea v-model="observacoes" rows="4" :disabled="desabilitaForm" />
                    </div>
                </div>
                <slot name="botoes"></slot>
                <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar" v-if="!desabilitaForm" />
                <Button
                    label="Salvar"
                    icon="pi pi-check-circle"
                    class="p-ml-2 p-button-primary"
                    @click="confirmarSalvar()"
                    v-if="!desabilitaForm"
                    :disabled="v$.$invalid"
                />
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import { tiposProjetos } from './tiposProjetos';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        acao: {
            type: String,
        },

        projeto: {
            type: Object,
        },

        erros: {
            type: Array,
        },

        cancelar: {
            type: Function,
        },
    },

    emits: ['salvar', 'atualizar'],

    data() {
        return {
            cnpj: null,
            nome: null,
            descricao: null,
            observacoes: null,
            tipo: null,
            tiposProjetos: [...tiposProjetos],
        };
    },

    validations() {
        return {
            cnpj: { required, minLength: minLength(14) },
            nome: { required },
            descricao: { required },
            tipo: { required },
        };
    },

    methods: {
        preencher() {
            this.cnpj = this.projeto ? this.projeto.cnpj : null;
            this.nome = this.projeto ? this.projeto.nome : null;
            this.descricao = this.projeto ? this.projeto.descricao : null;
            this.observacoes = this.projeto ? this.projeto.observacoes : null;
            this.tipo = this.projeto ? this.projeto.tipo : null;
            this.v$.$touch();
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja ${this.acao.toLowerCase()} o projeto?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            let projetoDto = {
                cnpj: this.cnpj,
                nome: this.nome,
                descricao: this.descricao,
                observacoes: this.observacoes ? this.observacoes : '',
                tipo: this.tipo,
            };
            this.$emit('salvar', projetoDto);
        },

        atualizar() {
            this.$emit('atualizar');
        },
    },

    computed: {
        desabilitaForm() {
            return this.acao === 'DETALHAR';
        },
    },

    watch: {
        projeto() {
            this.preencher();
        },
    },
};
</script>
