<template>
    <projeto-form acao="INSERIR" @salvar="inserir($event)" :cancelar="cancelar" :erros="erros"></projeto-form>
</template>

<script>
import ProjetoServices from './services';
import ProjetoForm from './ProjetoForm';

export default {
    components: {
        ProjetoForm,
    },

    data() {
        return {
            erros: [],
        };
    },

    methods: {
        cancelar() {
            this.$router.push({ name: 'Projetos' });
        },

        inserir(projetoNovo) {
            this.$store.dispatch('addRequest');
            let projetoNovoDto = {
                cnpj: projetoNovo.cnpj,
                nome: projetoNovo.nome,
                descricao: projetoNovo.descricao,
                tipo: projetoNovo.tipo,
                observacoes: projetoNovo.observacoes,
            };
            ProjetoServices.inserirProjeto(projetoNovoDto).then((response) => {
                if (response && response.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Projeto',
                        detail: 'Projeto inserido com sucesso',
                        life: 3000,
                    });
                    this.toDetalhar(response.data);
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toDetalhar(projetoId) {
            this.$store.dispatch('setAtualizar', true);
            this.$router.push({
                name: 'Projetos_Detalhar',
                params: {
                    id: projetoId,
                },
            });
        },
    },
};
</script>
